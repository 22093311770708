import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Table from '../../Components/Table/Table'
import ApprovalTable from '../../Components/Table/ApprovalTable';

const TournamentFishes = () => {

    return (
            <Routes>
                <Route path="/" element={<Table />} />
                <Route path="/:tournament_id" element={<ApprovalTable />} />
            </Routes>
    )
}

export default TournamentFishes