import { createContext, useContext, useEffect, useState } from "react";
import appUrl from "../appUrl";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export const AuthContext = createContext();

const appurl = appUrl();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem("token") || null);

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
  });
  const authorizationToken = `Bearer ${token}`;

  const [sidebar,setSidebar]= useState(true);

  const storeTokenInLS = async(serverToken) => { 
      localStorage.setItem('token', serverToken);
      console.log(serverToken,"serverToken")
      setToken(serverToken);
      localStorage.setItem('isLoggedIn', true); 
      console.log("Token stored in localStorage:", serverToken);
      setIsLoggedIn(true);
      // window.location.reload()
  };
  //  localStorage.setItem("isLoggedIn",isLoggedIn)


  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token"); 
    setToken("");
  };

  return (
    <>
      <AuthContext.Provider value={{isLoggedIn, storeTokenInLS, handleLogout,authorizationToken,sidebar,setSidebar}}>
        {children}
      </AuthContext.Provider>
      <NotificationContainer />
    </>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return authContext;
};
