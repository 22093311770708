import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
import { RxDashboard } from 'react-icons/rx';
import { useAuth } from '../../Store/useAuth';
import {useLocation } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { RiTeamFill } from "react-icons/ri";
import { GiCirclingFish } from "react-icons/gi";
import '../../Components/Modal.css'
import appUrl from "../../appUrl";

const AssistantSidebar = () => {
  const AppUrl = appUrl();
  const { setSidebar } = useAuth();
  const [activeItem, setActiveItem] = useState('');
  const location = useLocation()
  const [logoutModal,setLogoutModal]= useState(false)

  useEffect(() => {
    const storedItem = sessionStorage.getItem('selectedItem');
    if (storedItem) {
      setActiveItem(storedItem);
    }
  }, [location]);

  const handleItemClick = (item) => {
    setActiveItem(item);
    sessionStorage.setItem('selectedItem', item);
      localStorage.removeItem('fishId');
      localStorage.removeItem('tournamentId');
  };

  const clickSidebar = () => {
    setSidebar(false)
  }

  // const logoutUser = ()=>{
  //   localStorage.setItem("isLoggedIn","false")
  //   window.location.reload()
  // }

  const logoutUser = async (e) => {
    try {
        const fishToken = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const response = await fetch(`${AppUrl}/api/v1/user/logout?id=${userId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${fishToken}`,
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        console.log(data);
        if (data.code === 200) {
            localStorage.removeItem("token")
            localStorage.setItem("isLoggedIn","false")
            window.location.reload()
        }
        else {
            NotificationManager.error(data.message);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
  

  return (
    <>
    <NotificationContainer/>
      <div className="sidebar">
        <div>
          <div className='sidebar-head'>
            <div className="d-flex align-items-center">
              <img src='/images/Frame.png' alt='Logo' />
              <h2>Fishing Tournament</h2>
            </div>
          </div>
          <ul>
            <Link to="/user-login/dashboard" key="dashboard" onClick={() => handleItemClick('dashboard')}>
              <li className={activeItem === 'dashboard' ? 'active' : ''} onClick={() => handleItemClick('dashboard')}>
                <RxDashboard /> Dashboard
              </li>
            </Link>
            <Link to="/user-login/active" key="active" onClick={() => handleItemClick('active')}>
              <li className={activeItem === 'active' ? 'active' : ''} >
                <FaUser /> Active Tournament
              </li>
            </Link>
            <Link to="/user-login/upcoming" key="upcoming" onClick={() => handleItemClick('upcoming')}>
              <li className={activeItem === 'upcoming' ? 'active' : ''} >
                <RiTeamFill /> Upcoming Tournaments
              </li>
            </Link>
            <Link to="/user-login/history" key="history" onClick={() => handleItemClick('history')}>
              <li className={activeItem === 'history' ? 'active' : ''} >
                <GiCirclingFish /> History
              </li>
            </Link>
          </ul>
        </div>
        <div className='logout-user' onClick={()=>setLogoutModal(true)}>
          <MdLogout /> Logout 
        </div>
      </div>

      <Modal
        show={logoutModal}
        onHide={() => setLogoutModal(false)}
        className='report-modal'
        backdrop="static">
        <Modal.Body className='logout-model'>
          <p>Are you sure you want<br /> to logout?</p>
          <div className='d-flex justify-content-center'>
            <Button className='btn-confirm' onClick={logoutUser}>Confirm</Button>
            <Button className='btn-cancel' onClick={() => setLogoutModal(false)}  >Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>
        </>
        );
  
};

export default AssistantSidebar;
