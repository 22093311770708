import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate, useLocation } from 'react-router-dom';
import appUrl from '../../appUrl';
import './Table.css'

const UserTable = () => {
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const AppUrl = appUrl()
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [completeData, setCompleteData] = useState([]);

    console.log(currentPage, "currentPage")
    console.log(pageSize, "size")
    console.log(totalPages, "totalPages")
    console.log(totalCount, "totalCount")
    console.log(completeData, "come")

    console.log(projects, "pro")
    console.log(searchText, "text")

    console.log(filteredProjects, "filtered")

    const handleSearchChange = (e) => {
        const text = e.target.value;
        setSearchText(text);
        // filterProjects(text);
    };

    useEffect(() => {
        if (searchText !== "") {
            filterProjects()
        } else {
            listUsers()
        }
    }, [searchText])

    const filterProjects = () => {
        const filtered = projects.filter(project =>
            Object.values(project).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
            )
        );
        setProjects(filtered);
    };

    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };
    const handlePageChange = (page, sizePerPage) => {
        setCurrentPage(page);
        setPageSize(sizePerPage);
    };

    useEffect(() => {
        listUsers();
    }, [currentPage]);

    const ActionFormatter = (cell, row, rowIndex) => {
        const userId = row._id;

        return (
            <Button
                className='view-button'
                onClick={() => {
                    navigate(`/admin-login/${selectedItem}/tournament-table/${userId}`);
                }}
            >
                View Tournament
            </Button>
        );
    };

    const FishActionFormatter = (cell, row) => {
        const tournament_id = row.tournament_id;

        return (
            <Button
                className='view-button'
                onClick={() => {
                    navigate(`/admin-login/${selectedItem}/${tournament_id}`);
                    localStorage.setItem("tournamentId",tournament_id)
                }}
            >
                View Tournament
            </Button>
        );
    };


    const listUsers = async () => {
        setLoading(true);
        try {
            const fishingToken = localStorage.getItem('token');
            let response;

            if (selectedItem === "users") {
                response = await fetch(`${AppUrl}/api/v1/auth/users`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${fishingToken}`,
                        'Content-Type': 'application/json',
                    }
                })
            }
            else if (selectedItem === "tournament") {
                response = await fetch(`${AppUrl}/api/v1/tournament/all`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${fishingToken}`,
                        'Content-Type': 'application/json',
                    }
                });
            }
            else if (selectedItem === "fish") {
                response = await fetch(`${AppUrl}/api/v1/user/fishes`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${fishingToken}`,
                        'Content-Type': 'application/json',
                    }
                });
            }
            const data = await response.json();
            console.log(data, "datatata");

            if (data.code === 200) {
                setProjects(data.data);
                setPageSize(data.pageSize);
                setTotalPages(data.totalPages);
                setTotalCount(data.totalCount);
                setLoading(false);
            } else {
                NotificationManager.error('Failed');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };

    const columns = [];

    if (selectedItem === "tournament") {
        columns.push(
            {
                formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
                dataField: 'index',
                text: 'S No.',
                headerClass: 'serial-number-header ',
                classes: 'serial-number-column',
            },
            {
                dataField: 'tournament_name',
                text: 'Tournament Name',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'start_date',
                text: 'Start Date',
                formatter: (cell) => {
                    const date = new Date(cell);
                    return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                },
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'end_date',
                text: 'End Date',
                formatter: (cell) => {
                    const date = new Date(cell);
                    return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                },
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'lines_in',
                text: 'Time in',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'lines_out',
                text: 'Time out',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: ActionFormatter,
                headerClass: 'status-button-header',
                classes: 'status-button-column',
            }
        );
    } else if (selectedItem === "users") {
        columns.push(
            {
                formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
                dataField: 'index',
                text: 'S No.',
                headerClass: 'serial-number-header ',
                classes: 'serial-number-column',
            },
            {
                dataField: 'name',
                text: 'Name',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'email',
                text: 'Email',
                filter: textFilter({ placeholder: '' }),
                // headerClass: 'email-header ',
                classes: 'email-column',
            },
            {
                dataField: 'gender',
                text: 'Gender',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'date_of_birth',
                text: 'Date of birth',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'mobileNumber',
                text: 'Mobile Number',
                filter: textFilter({ placeholder: '' }),
            },
        )
    } else if (selectedItem === "fish") {
        columns.push(
            {
                formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
                dataField: 'index',
                text: 'S No.',
                headerClass: 'serial-number-header ',
                classes: 'serial-number-column',
            },
            {
                dataField: 'tournament_name',
                text: 'Tournament Name',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'start_date',
                text: 'Start Date',
                formatter: (cell) => {
                    const date = new Date(cell);
                    return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                },
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'end_date',
                text: 'End Date',
                formatter: (cell) => {
                    const date = new Date(cell);
                    return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                },
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'total_fishes_with_pending_status',
                text: 'Approval Pending',
                style: (cell, row, rowIndex, colIndex) => {
                    return { color: 'red', fontWeight: 600 }; // Change 'red' to any color you prefer
                },
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: FishActionFormatter,
                headerClass: 'status-button-header',
                classes: 'status-button-column',
            }
        )
    }


    return (
        <>
            <NotificationContainer />

            <div className="show-entries">
                <p></p>

                <div>
                    <Form.Group controlId="searchInput">
                        <Form.Control
                            type="text"
                            placeholder="Search anything here"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Form.Group>
                </div>
            </div>


            {loading ? (
                <div className='loading-spinner' >
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                <div className='mb-5'>
                    <BootstrapTable
                        bootstrap4
                        keyField="_id"
                        data={projects}
                        columns={columns}
                        filter={filterFactory({ placeholder: '' })}
                        pagination={paginationFactory({
                            page: currentPage,
                            sizePerPage: pageSize,
                            totalSize: totalCount,
                            onPageChange: handlePageChange,
                            sizePerPageRenderer: () => null, // Remove the size per page dropdown
                            showTotal: true, // Enable showing total entries
                            paginationTotalRenderer: (from, to, total) => (
                                <div className="pagination-and-entries">
                                    <p className="entries">
                                        Showing {from} to {to} of {total} entries
                                    </p>
                                </div>
                            ),
                        })}
                    />
                </div>
            )
            }

        </>
    )
};

export default UserTable;


