import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Table from '../../Components/Table/Table'

const RegisteredUsers = () => {

    return (
            <Routes>
                <Route path="/" element={<Table />} />
            </Routes>
    )
}

export default RegisteredUsers