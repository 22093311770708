import React, { useEffect, useState } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";
import appUrl from '../../appUrl';
// import './Table.css'
import './TournamentDetails.css'
import { IoMdTime } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";

const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(`2000-01-01T${timeString}`).toLocaleTimeString('en-US', options);
};

const TournamentDetails = () => {
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const AppUrl = appUrl()
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({});

    console.log(details, "selectedP")
    // console.log(details.teams[0].team_name, "team")

    const Details = async () => {
        setLoading(true);
        console.log(id, "Apii")
        try {
            const fishingToken = localStorage.getItem('token');
            const response = await fetch(`${AppUrl}/api/v1/tournament/getTournament?id=${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.code === 200) {
                console.log("called")
                setDetails(data.data);
                setLoading(false);
            } else {
                console.error('Failed to fetch projects of the selected user');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        Details();
    }, []);

    function formatUserRole(teamMembersType) {
        return teamMembersType.map(role => role.replace(/_/g, ' ')).join(', ');
    }

    return (
        <>
            <NotificationContainer />

            <div className='details'>
                
                {/* <div>
                    <div className=" mb-4">
                        <IoMdArrowBack onClick={() => navigate(`/home/${selectedItem}`)} />
                    </div>
                </div> */}
                {/* <div className='details'>
                    <h3 className='details mb-3'>Tournament Name: <span>{details.tournament_name}</span></h3>
                    <p>Start Date: <span>{details.start_date}</span> </p>
                    <p>End Date: <span>{details.end_date}</span></p>
                    <p>Times in: <span>{details.lines_in}</span></p>
                    <p>Times out: <span>{details.lines_out}</span></p>
                </div> */}

                <div className='details-card mb-5'>
                    <div className='details-head'>
                        <h3>{details.tournament_name}</h3>
                    </div>
                    <hr />
                    <div>
                        <p><CiCalendarDate />{formatDate(details.start_date)} to {formatDate(details.end_date)}</p>
                    </div>
                    <div>
                        <p><IoMdTime />{formatTime(details.lines_in)} to {formatTime(details.lines_out)}</p>
                    </div>
                </div>

                <div className='details-title mb-3'>Setup</div>
                <div>
                    <div>
                        <Row className='checkbox-table'>
                            <Col>
                                <div className='selected'>
                                    Sound Horns at Lines in/Lines Out
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.sound_horns}
                                        readOnly
                                    />
                                </div>
                                <div className='selected'>
                                    Are images required?
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.image_required}
                                        readOnly
                                    />
                                </div>
                                <div className='selected'>
                                    Is picture Geo location required?
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.geolocation_required}
                                        readOnly
                                    />
                                </div>
                                <div className='selected'>
                                    Catch must be verified
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.catch_verified}
                                        readOnly
                                    />
                                </div>
                                <div className='selected'>
                                    Hold Tournament Results Each Day
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.hold_tournament}
                                        readOnly
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='multipliers'>
                        <Table>
                            <thead className='multiplier-head'>
                                <tr>
                                    <th>S.No</th>
                                    <th>Fishing style</th>
                                    <th>Multipliers</th>
                                    <th>Points</th>
                                    <th>Length</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.set_up?.tackle_multiplier && details.set_up.tackle_multiplier.length > 0 ? (
                                    details.set_up.tackle_multiplier.map((tackle, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{tackle.fishing_style}</td>
                                            <td>{tackle.multipliers}</td>
                                            <td><input type='checkbox' checked={details.points} readOnly /></td>
                                            <td><input type='checkbox' checked={details.length} readOnly /></td>
                                            <td><input type='checkbox' checked={details.weight} readOnly /></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">-</td>
                                    </tr>
                                )}
                            </tbody>

                        </Table>
                    </div>
                </div>

                <div>
                    {/* <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src="holder.js/100px180" />
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <Card.Text>
                            </Card.Text>
                            <Button variant="primary">Go somewhere</Button>
                        </Card.Body>
                    </Card> */}
                </div>
                <div className='details-title'>Species</div>
                <Table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Species Name</th>
                            <th>Points</th>
                            <th>Min Weight</th>
                            <th>Min Length</th>
                            <th>Daily Limit</th>
                            <th>Tournament Limit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.species && details.species.length > 0 ? (
                            details.species.map((species, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{species.speciesName}</td>
                                    <td>{species.speciesPoints}</td>
                                    <td>{species.min_weight}</td>
                                    <td>{species.min_length}</td>
                                    <td id='limit'>{species.daily_limit}</td>
                                    <td id='limit'>{species.tournament_limit}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No species available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <div className='details-title'>Teams</div>
                <Table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Team Photo</th>
                            <th>Team Name</th>
                            <th>No. of Participants</th>
                            {/* <th>No. of Guide</th>
                            <th>Angler</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {details.teams && details.teams.length > 0 ? (
                            details.teams.map((team, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><img src={team.team_photo} alt='team' /></td>
                                    <td>{team.team_name}</td>
                                    <td>{team.speciesPoints}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No teams available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <div className='details-title'>Participants</div>
                <Table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Participant Name</th>
                            <th>Participant Role</th>
                            <th>Team</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.team_members && details.team_members.length > 0 ? (
                            details.team_members.map((members, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{members.team_members_name}</td>
                                    <td>{formatUserRole(members.team_members_type)}</td>
                                    <td>{members.select_team && members.select_team[0]}</td>
                                    <td style={{textTransform:"lowercase"}}>{members.team_members_email}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No participants available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <div className='details-title'>Tournament Rules</div>
                <div className='rules'><li>{details.tournament_rules}</li></div>
            </div>
        </>
    )
};

export default TournamentDetails;



