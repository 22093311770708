import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Table from '../../Components/Table/Table'
import TournamentDetails from '../../Components/Table/TournamentDetails';

const Tournament = () => {
  return (
    <div>
    <Routes>
        <Route path="/" element={<Table />} />
        <Route path="tournament-table/:id" element={<TournamentDetails />} />
    </Routes>
</div>
  )
}

export default Tournament
