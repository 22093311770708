import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { Card } from 'react-bootstrap';
import appUrl from '../../appUrl';

const Dashboard = () => {
  const AppUrl = appUrl();
  const [count, setCount] = useState({
    tournamentCount: '',
    userCount: ''
  });

  const [cardsData, setCardsData] = useState([
    {
      id: 1,
      text: 'Users',
      bgColor: '#00555C',
      color:"white",
      // img: '/images/reward.png',
      count: ''
    },
    {
      id: 2,
      text: 'Tournaments',
      bgColor: '#E0EFF6',
      color:"#253f42",
      // img: '/images/refund.png',
      count: ''
    },
  ]);

  useEffect(() => {
    listData();
  }, []);

  const listData = async () => {
    try {
      const fishingToken = localStorage.getItem('token');

      const response = await fetch(`${AppUrl}/api/v1/tournament/count`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log(data, "datatata");

      if (data.code === 200) {
        setCount({
          tournamentCount: data.data.tournaments.count,
          userCount: data.data.users.count
        });

        // Update card counts dynamically based on the count retrieved from API
        setCardsData(prevCardsData => prevCardsData.map(card => {
          if (card.text === 'Tournaments') {
            return { ...card, count: data.data.tournaments.count };
          } else if (card.text === 'Users') {
            return { ...card, count: data.data.users.count };
          } else {
            return card;
          }
        }));
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className='renovation'>
        <div className='inspection'>
          <div className='inspection-row'>
            {/* <h6>Accrued Rewards</h6> */}
            <div className="d-flex cards">
              {cardsData.map((card) => (
                <Card
                  key={card.id}
                  style={{ width: '18rem', margin: '6px', backgroundColor: card.bgColor,color: card.color }}
                  className='inspection-card'
                >
                  <Card.Body>
                    {/* <Card.Img src={card.img} className='card-img' /> */}
                    <Card.Text>{card.text}</Card.Text>
                    <Card.Title>{card.count}</Card.Title>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
