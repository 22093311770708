import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { IoIosArrowForward } from 'react-icons/io';
import * as Yup from 'yup';
import { useAuth } from '../Store/useAuth';
import appUrl from '../appUrl';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './Login.css';

const AssistantLogin = () => {
    const AppUrl = appUrl();
    const navigate = useNavigate();
    const { storeTokenInLS, setIsLoggedIn } = useAuth();

    const [login, setLogin] = useState({ phone_number: '', otp: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [otpLoader, setOtpLoader] = useState(false)

    console.log(login, "log")

    // useEffect(() => {
    //     localStorage.removeItem('isLoggedIn');
    //     localStorage.removeItem('token');
    //     sessionStorage.removeItem('selectedItem');
    // }, []);

    const handleChange = (name, value, setFieldValue) => {
        setFieldValue(name, value);
        setLogin((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleLog = async (values) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${AppUrl}/api/v1/user/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            });
            const data = await response.json();

            if (data.code === 200) {
                NotificationManager.success(data.message);
                setIsLoading(false);
                localStorage.setItem("isAdmin", "false")
                localStorage.setItem("loggedBy", data.username)
                localStorage.setItem("userId", data._id)
                storeTokenInLS(data.token);
                navigate('/user-login/dashboard')
            } else {
                NotificationManager.error(data.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            // NotificationManager.error('Network error, please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const getOtp = async () => {
        setOtpLoader(true);
        try {
            const response = await fetch(`${AppUrl}/api/v1/user/otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phone_number: login.phone_number }),
            });

            const data = await response.json();
            if (response.ok && data.code === 200) {
                NotificationManager.success(data.message);
                setOtpLoader(false);
            } else {
                NotificationManager.error(data.message);
                setOtpLoader(false);
            }
        } catch (error) {
            console.error('Error:', error);
            NotificationManager.error('Network error, please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const schema = Yup.object().shape({
        phone_number: Yup.string().required('Phone number is required'),
        otp: Yup.string().required('OTP is required'),
    });

    return (
        <>
            <NotificationContainer />
            <div className='signup'>
                <div className='signup-card'>
                    <div className='welcome-head mb-4'>
                        <img src='/images/Frame.png' alt='logo' />
                        <h1>Welcome</h1>
                    </div>
                    <Formik
                        validationSchema={schema}
                        initialValues={{ phone_number: '', otp: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                            handleLog(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ setFieldValue }) => (
                            <Form className=' mb-3'>
                                <div className='signup-fields mb-3'>
                                    <label htmlFor='phone_number'>Phone Number</label>
                                    <PhoneInput
                                        country={'in'}
                                        value={login.phone_number}
                                        onChange={(phone) => handleChange('phone_number', `+${phone}`, setFieldValue)}
                                        inputProps={{
                                            name: 'phone_number',
                                            required: true,
                                            autoFocus: true,
                                        }}
                                    />
                                </div>
                                <div className='error-wrapper mb-3'>
                                    <ErrorMessage name='phone_number' component='div' />
                                </div>

                                <div className='signup-fields mb-3'>
                                    <label htmlFor='otp'>OTP</label>
                                    <Field
                                        type='text'
                                        id='otp'
                                        name='otp'
                                        autoComplete='off'
                                        onChange={(e) => handleChange(e.target.name, e.target.value, setFieldValue)}
                                    />
                                </div>
                                <div className='error-wrapper mb-3'>
                                    <ErrorMessage name='otp' component='div' />
                                </div>

                                <div className='SignUp-button mb-3'>
                                    {/* <Button onClick={getOtp}>
                                        Get OTP <IoIosArrowForward />
                                    </Button> */}
                                    <Button
                                        className='btn-confirm'
                                        onClick={getOtp}
                                        disabled={otpLoader}
                                        backdrop="static">
                                        {otpLoader ? (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='delete-spinner'
                                            />
                                        ) : (
                                            <p>Get OTP <IoIosArrowForward /></p>
                                        )}
                                    </Button>
                                </div>

                                <div className='SignUp-button mb-3'>
                                    <Button
                                        type='submit'
                                        className='btn-confirm'
                                        disabled={isLoading}
                                        backdrop="static">
                                        {isLoading ? (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='delete-spinner'
                                            />
                                        ) : (
                                            <p>Log in <IoIosArrowForward /></p>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AssistantLogin;
