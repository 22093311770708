// Import necessary dependencies
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "../src/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { AuthProvider } from './Store/useAuth';
import Sidebar from "./Components/Sidebar/Sidebar";
import RegisteredUsers from "./Pages/Users.js/RegisteredUsers";
import UserName from "./Components/UserName";
import "./App.css";
import Tournament from "./Pages/Tournament/Tournament";
import TournamentFishes from "./Pages/Fishes/TournamentFishes";
import AssistantSidebar from "./Components/Sidebar/AssistantSidebar";
import ActiveTournament from "./Pages/Assistant/ActiveTournaments";
import UpcomingTournament from "./Pages/Assistant/UpcomingTournament";
import HistoryTournament from "./Pages/Assistant/HistoryTournaments";
import AssistantDashboard from "./Pages/Dashboard/AssistantDashboard";
import AdminLogin from './Login/AdminLogin';
import AssistantLogin from './Login/AssistantLogin';

// Custom Route Wrapper for Admin and User Paths
const PrivateRoute = ({ component: Component, role, ...rest }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const location = useLocation();

  console.log("location",location)
  useEffect(() => {
    console.log(`Current Path: ${location.pathname}`);
  }, [location]);

  // Check if the user is not logged in
  if (!isLoggedIn) {
    // Check if the path contains "admin" or "user" and navigate accordingly
    if (location.pathname.includes('admin')) {
      return <Navigate to="/admin-login" />;
    } else if (location.pathname.includes('user')) {
      return <Navigate to="/user-login" />;
    }
  }

  console.log(role,"role")
  // Admin-specific logic
  if (role === 'admin' && isAdmin) {
    return <Component {...rest} />;
  }

  // Assistant-specific logic
  if (role === 'user' && !isAdmin) {
    return <Component {...rest} />;
  }

  return <Navigate to="/" />; // Fallback if the role doesn't match
};

function App() {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  return (
    <Router>
      <div>
        <Routes>
          {/* Admin Login Page */}
          <Route path="/admin-login" element={<AdminLogin />} />

          {/* User Login Page */}
          <Route path="/user-login" element={<AssistantLogin />} />

          {/* Admin Pages */}
          <Route
            path="/admin-login/*"
            element={<PrivateRoute
              component={() => (
                <>
                  <Row className='m-0'>
                    <Col className="col_1_open" lg={3} md={3}>
                      <Sidebar />
                    </Col>
                    <Col className="col_2_open" lg={9} md={9}>
                      <div className='bellicon'>
                        <UserName />
                      </div>
                      <div className="pages">
                        <Routes>
                          <Route path="dashboard" element={<Dashboard />} />
                          <Route path="users/*" element={<RegisteredUsers />} />
                          <Route path="tournament/*" element={<Tournament />} />
                          <Route path="fish/*" element={<TournamentFishes />} />
                        </Routes>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              role="admin"
            />}
          />

          {/* Redirect to the correct dashboard based on the role */}
          <Route
            path='/'
            element={isLoggedIn ? (isAdmin ? <Navigate to="/admin-login/dashboard" /> : <Navigate to="/user-login/dashboard" />) : <Login />}
          />

          {/* User Pages */}
          <Route
            path="/user-login/*"
            element={<PrivateRoute
              component={() => (
                <>
                  <Row className='m-0'>
                    <Col className="col_1_open" lg={3} md={3}>
                      <AssistantSidebar />
                    </Col>
                    <Col className="col_2_open" lg={9} md={9}>
                      <div className='bellicon'>
                        <UserName />
                      </div>
                      <div className="pages">
                        <Routes>
                          <Route path="dashboard" element={<AssistantDashboard />} />
                          <Route path="active/*" element={<ActiveTournament />} />
                          <Route path="upcoming/*" element={<UpcomingTournament />} />
                          <Route path="history/*" element={<HistoryTournament />} />
                        </Routes>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              role="user"
            />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default function WrappedApp() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}
