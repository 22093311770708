import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import AdminLogin from './AdminLogin';
import AssistantLogin from './AssistantLogin';
import './Login.css';

const Login = () => {
    return (
        <>
            <NotificationContainer />
                <div className='signup'>
                    <div className='signup-card'>
                        <div className='welcome-head mb-4'>
                            <img src='/images/Frame.png' alt='logo' />
                            <h1>Welcome</h1>
                        </div>
                        <Tabs defaultActiveKey='admin' id='uncontrolled-tab-example' className='mb-3'>
                            <Tab eventKey='admin' title='Admin'>
                                <AdminLogin />
                            </Tab>
                            <Tab eventKey='assistant' title='User'>
                                <AssistantLogin />
                            </Tab>
                            {/* <Tab eventKey='director' title='Director'>
                                <AssistantLogin />
                            </Tab> */}
                        </Tabs>
                    </div>
                </div>
        </>
    );
};

export default Login;
