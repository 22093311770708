import React from 'react'
import { Routes, Route } from 'react-router-dom';
import FetchTournaments from '../../Components/Table/FetchTournaments';
import TournamentDetails from '../../Components/Table/TournamentDetails';

const UpcomingTournament = () => {
  return (
    <div>
    <Routes>
        <Route path="/" element={<FetchTournaments />} />
        <Route path="/:id" element={<TournamentDetails />} />
    </Routes>
</div>
  )
}

export default UpcomingTournament
