import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
import { Modal,Button } from 'react-bootstrap';
import { RxDashboard } from 'react-icons/rx';
import { RiArrowLeftDoubleLine } from 'react-icons/ri';
import { useAuth } from '../../Store/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { RiTeamFill } from "react-icons/ri";
import { GiCirclingFish } from "react-icons/gi";
import '../../Components/Modal.css'

const Sidebar = () => {
  const { setSidebar } = useAuth();
  const [activeItem, setActiveItem] = useState('');
  const location = useLocation()
  const [logoutModal,setLogoutModal]= useState(false)

  useEffect(() => {
    const storedItem = sessionStorage.getItem('selectedItem');
    if (storedItem) {
      setActiveItem(storedItem);
    }
  }, [location]);

  const handleItemClick = (item) => {
    setActiveItem(item);
    sessionStorage.setItem('selectedItem', item);

    // if (item === 'renovation' || item === 'repair' || item === 'inspection') {
    //   localStorage.removeItem('user-email');
    //   localStorage.removeItem('userId');
    //   localStorage.removeItem('savedAddresses');
    // }

  };

  const clickSidebar = () => {
    setSidebar(false)
  }

  const logoutUser = ()=>{
    localStorage.setItem("isLoggedIn","false")
    window.location.reload()
  }

  return (
    <>
      <div className="sidebar">
        <div>
          <div className='sidebar-head'>
            <div className="d-flex align-items-center">
              <img src='/images/Frame.png' alt='Logo' />
              <h2>Fishing Tournament</h2>
            </div>
            {/* <RiArrowLeftDoubleLine className="arrow-icon" onClick={clickSidebar} /> */}
          </div>
          <ul>
            <Link to="/admin-login/dashboard" key="dashboard">
              <li className={activeItem === 'dashboard' ? 'active' : ''} onClick={() => handleItemClick('dashboard')}>
                <RxDashboard /> Dashboard
              </li>
            </Link>
            <Link to="/admin-login/users" key="users">
              <li className={activeItem === 'users' ? 'active' : ''} onClick={() => handleItemClick('users')}>
                <FaUser /> Users
              </li>
            </Link>
            <Link to="/admin-login/tournament" key="tournament">
              <li className={activeItem === 'tournament' ? 'active' : ''} onClick={() => handleItemClick('tournament')}>
                <RiTeamFill /> Tournaments
              </li>
            </Link>
            <Link to="/admin-login/fish" key="fish">
              <li className={activeItem === 'fish' ? 'active' : ''} onClick={() => handleItemClick('fish')}>
                <GiCirclingFish /> Captured Fish
              </li>
            </Link>
          </ul>
        </div>
        <div className='logout-user' onClick={()=>setLogoutModal(true)}>
          <MdLogout /> Logout 
        </div>
      </div>

      <Modal
        show={logoutModal}
        onHide={() => setLogoutModal(false)}
        className='report-modal'
        backdrop="static">
        <Modal.Body className='logout-model'>
          <p>Are you sure you want<br /> to logout?</p>
          <div className='d-flex justify-content-center'>
            <Button className='btn-confirm' onClick={logoutUser}>Confirm</Button>
            <Button className='btn-cancel' onClick={() => setLogoutModal(false)}  >Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>
        </>
        );
};

export default Sidebar;
