import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { Button } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../Store/useAuth';
import appUrl from '../appUrl';
import './Login.css';

const AdminLogin = () => {
    const AppUrl = appUrl();
    const { storeTokenInLS, setIsLoggedIn } = useAuth();
    const navigate = useNavigate();

    const [login, setLogin] = useState({ username: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');
        sessionStorage.removeItem('selectedItem');
    }, []);

    const handleShowPassword = () => setShowPassword(true);
    const handleHidePassword = () => setShowPassword(false);

    const handleChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        setFieldValue(name, value);
        setLogin((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleLog = async (values) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${AppUrl}/admin/v1/auth/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            });

            const data = await response.json();
            if (response.ok && data.code === 200) {
                NotificationManager.success(data.message);
                localStorage.setItem("isAdmin", "true")
                localStorage.setItem("loggedBy", "admin")
                storeTokenInLS(data.data.token);
                navigate('/admin-login/dashboard')
            } else {
                NotificationManager.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            NotificationManager.error('Network error, please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const schema = Yup.object().shape({
        username: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    return (
        <>

            <NotificationContainer />
            <div className='signup'>
                <div className='signup-card'>
                    <div className='welcome-head mb-4'>
                        <img src='/images/Frame.png' alt='logo' />
                        <h1>Welcome</h1>
                    </div>

                    <Formik
                        validationSchema={schema}
                        initialValues={{ username: '', password: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                            handleLog(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ setFieldValue }) => (
                            <Form className='mb-3' autoComplete='off'>
                                <div className='signup-fields mb-3'>
                                    <label htmlFor='username'>EMAIL</label>
                                    <Field
                                        type='username'
                                        id='username'
                                        name='username'
                                        autoComplete='off'
                                        onChange={(e) => handleChange(e, setFieldValue)}
                                    />
                                </div>
                                <div className='error-wrapper'><ErrorMessage
                                    className='mb-4' name="username" component="div" /></div>

                                <div className='signup-fields mb-3'>
                                    <label htmlFor='password'>PASSWORD</label>
                                    <Field
                                        type={showPassword ? 'text' : 'password'}
                                        id='password'
                                        name='password'
                                        autoComplete='off'
                                        onChange={(e) => handleChange(e, setFieldValue)}
                                    />
                                    {showPassword ? (
                                        <IoEyeOutline className='eye' onClick={handleHidePassword} />
                                    ) : (
                                        <IoEyeOffOutline className='eye' onClick={handleShowPassword} />
                                    )}
                                </div>
                                <div className='error-wrapper'>
                                    <ErrorMessage name='password' component='div' className=' mb-3' />
                                </div>

                                <div className='SignUp-button mb-3'>
                                    <Button type='submit' disabled={isLoading}>
                                        Log in <IoIosArrowForward />
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div >
            </div >

        </>
    );
};

export default AdminLogin;
